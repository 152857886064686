import * as React from 'react';

import Seo from '../components/global/seo';
import Layout from '../components/global/layout';
import Contact from '../components/services/contact';
import Services from '../components/services/services';

const ServicesPage = () => {
  return (
    <Layout>
      <Services />
      <Contact />
    </Layout>
  );
};

export const Head = () => <Seo title='Services' />;

export default ServicesPage;
