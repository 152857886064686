import { Link } from 'gatsby';
import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import MobileMaintenance from 'images/mobilemaintenance.png';
import Gallery4 from 'images/Gallery4.jpg';
import Gallery7 from 'images/Gallery8.jpg';
import Gallery1 from 'images/Gallery9.jpg';
import Gallery10 from 'images/Gallery10.jpg';
import Gallery11 from 'images/Gallery11.jpg';

import 'styles/pages/services/services.scss';

const Services = () => {
  return (
    <div className='services-page'>
      <div className='title'>
        <h1>Services</h1>
      </div>
      <div className='services-container'>
        <div className='services-repairs'>
          <div className='services-repairs-container'>
            <article className='repairs-content'>
              <h2 className='repairs-title'>Maintenance & Repairs</h2>
              <p className='repairs-desc'>
                Mobile Air Service works with small, private aircrafts. We work
                on Beechcraft King Air 200, Piper Chieftain, Cirrus, Lake
                Renegade and Cessna 100 series. Specialized in turbine engine
                maintenance & piston engine maintenance on aircrafts with a MTOW
                of 5700 kg and below. And we also work with Airbus Helicopters,
                AS350 series and Bell 206 series helicopters.
              </p>
              <p className='repairs-desc repairs-desc--2'>
                Check below for our full list of aircrafts we are authorized to
                work on.
              </p>
              <Link to='/about' className='repairs-about'>
                Full aircraft list
              </Link>
            </article>
            <div className='services-images-container'>
              <div className='services-image services-image--1'>
                <LazyLoadImage
                  className='image-1'
                  src={Gallery4}
                  alt='Canada Helicopter'
                />
              </div>
              <div className='services-image services-image--2'>
                <LazyLoadImage
                  className='image-2'
                  src={Gallery1}
                  alt='Airplane on runway'
                />
              </div>
              <div className='services-image services-image--3'>
                <LazyLoadImage
                  className='image-3'
                  src={Gallery11}
                  alt='Airplane in hangar'
                />
              </div>
              <div className='services-image services-image--4'>
                <LazyLoadImage
                  className='image-4'
                  src={MobileMaintenance}
                  alt='Airplane with open panel'
                />
              </div>
              <div className='services-image services-image--5'>
                <LazyLoadImage
                  className='image-5'
                  src={Gallery7}
                  alt='Multiple Aircrafts lined up'
                />
              </div>
              <div className='services-image services-image--6'>
                <LazyLoadImage
                  className='image-6'
                  src={Gallery10}
                  alt='Airplane with engine panel open'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='services-feature-boxes'>
          <figure className='service-box'>
            <i class='uil uil-search'></i>
            <h2>Inspections</h2>
            <p>
              We are able to do pre-purchase inspections for the aircrafts your
              looking to purchase and we are able to do all your annual
              inspections to make sure everything is up to date and certified.
            </p>
          </figure>
          <figure className='service-box'>
            <i class='uil uil-favorite'></i>
            <h2>Appraisals</h2>
            <p>
              With our in-house expertise we'll give you an unbiased opinion of
              the value of any aircraft based on its current condition. Whether
              your buying or selling we know aircrafts let us help with the
              price.
            </p>
          </figure>
          <figure className='service-box'>
            <i class='uil uil-cube'></i>
            <h2>Structural Repairs</h2>
            <p>
              As an AMO entrusted by Transport Canada with the "Structure
              Category" rating, we are able to help you with all your
              specialized structural repairs on all sheet metal structures.
            </p>
          </figure>
          <figure className='service-box'>
            <i class='uil uil-bookmark'></i>
            <h2>Decals & Placards</h2>
            <p>
              Decals and placards for your registration numbers available for
              install using the highest quality materials made for exteriors of
              all types of aircrafts.
            </p>
          </figure>
        </div>
        <p className='contact-text'>
          Still unsure if we can get the job done?{' '}
          <Link to='/contact'>Give us a shout</Link> and we'll get back to you
          ASAP.
        </p>
      </div>
    </div>
  );
};

export default Services;
